import React from "react";
import {NavLink} from "react-router-dom"
import { useEffect } from 'react'

const Header = (props) => {
  useEffect(() => {
    let scripts = [
      { src: "https://unpkg.com/aos@2.3.1/dist/aos.js" },
      {
        src: "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js",
        integrity: "sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p",
        crossOrigin: "anonymous"
      },
      { src: "vendor/glightbox/js/glightbox.min.js" },
      { src: "https://cdnjs.cloudflare.com/ajax/libs/jquery.isotope/3.0.6/isotope.pkgd.min.js", },
      { src: "vendor/swiper/swiper-bundle.min.js" },
      { src: "js/main.js" }
    ]
    scripts.forEach(item => {
      const script = document.createElement("script")
      script.src = item.src
      if(item?.integrity){
        script.integrity = item.integrity
      }
      if(item?.crossOrigin){
        script.crossOrigin = item.crossOrigin
      }
      script.async = true
      document.body.appendChild(script)
    })
  }, [])
    return (
        <header id="header" className="header fixed-top">
          <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
            <a href="/" className="logo d-flex align-items-center">
              <img src={ require("../assets/img/logo.png")} alt="" />
            </a>
            <nav id="navbar" className="navbar">
              <ul>
                <li><NavLink className="nav-link scrollto" to="/">Home</NavLink></li>
                <li><NavLink className="nav-link scrollto" to="/pricing">Pricing</NavLink></li>
                <li><a className="nav-link scrollto"  target="_blank" href="https://app.redtie.co/">Try Us Free</a></li>
                <li><a className="nav-link scrollto" target="_blank" href="https://app.redtie.co/">Login</a></li>       
              </ul>
              <i className="bi bi-list mobile-nav-toggle" />
            </nav>
          </div>
        </header>
      );
}

export default Header;
