import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import $ from "jquery";
const ContactUs = () => {
  const sendMessage = async (e) => {
    try {
      e.preventDefault();
      var name = $("#name").val();
      var email = $("#email_id").val();
      var form_mobile = $("#form_mobile").val();
      var form_message = $("#form_message").val();
      const rawResponse = await fetch("https://app.redtie.co:2021/apiv4/email/contactus", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "apikey": "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
            "secret": "R1eqD2twI3E4",
            "name": name,
            "phone": form_mobile,
            "email": email,
            "message": form_message
        }),
      });
      const content = await rawResponse.json();
      if(content.status ==='success'){
          $(".sent-message").show();
          $("#contact_us_message_form")[0].reset();
          setTimeout(() => {
            $(".sent-message").hide();
          }, 2000);
      }
      console.log("contenmt",content);
    } catch (error) {
        $(".error-message").show().text('Sorry, Please try again later.');
          setTimeout(() => {
            $(".error-message").hide();
          }, 2000);
    }
  };
  return (
    <>
      <Header />
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <header className="section-header mt-5 pt-5">
            <h2>Contact Us</h2>
            {/* <p>Contact Us</p> */}
          </header>
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-geo-alt" />
                    <h3>Address</h3>
                    <p>
                      PO Box 429,
                      <br />
                      Corte Madera, CA 94925
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-telephone" />
                    <h3>Call Us</h3>
                    <p>+1 484-874-5535</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-envelope" />
                    <h3>Email Us</h3>
                    <p>getredtie@redtiemail.com</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-clock" />
                    <h3>Open Hours</h3>
                    <p>
                      Monday - Friday
                      <br />
                      9:00AM - 05:00PM
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <form
                onSubmit={(e) => sendMessage(e)}
                method="post"
                className="php-email-form" id="contact_us_message_form"
              >
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 ">
                    <input
                      type="email"
                      id="email_id"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      id="form_mobile"
                      className="form-control"
                      name="mobile"
                      placeholder="Mobile"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      id="form_message"
                      name="message"
                      rows={6}
                      placeholder="Message"
                      required
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <div className="loading">Loading</div>
                    <div className="error-message" />
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
