import React from 'react'

const HowItWorks = () => {
    return (
        <div>
            <section id="values" className="values bg_purble">
                <div className="container" data-aos="fade-up">
                    <header className="section-header purple_txt_clr">
                        <p>Stay connected with your contacts using Redtie. You may send the message with or without attachments. 
Every attachment access is captured and tracked so you make decisions for next steps.</p>
                    </header>
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <video className="video_custom" loop={true} autoPlay="autoplay" controls muted>
                                <source src='https://s3.amazonaws.com/file.redtie/redtie-board/redtie-board-how-it-works.mp4' type="video/mp4" />
                            </video>
                        </div>
                        <div className="row mt-4 mb-4">
                            <div className="col-sm-12 d-flex justify-content-center">
                                <div data-aos="fade-up" data-aos-delay={600}>
                                    <div className="text-center text-lg-start">
                                        <a href="https://www.youtube.com/Redtie" target="_blank" className="btn-get-started d-inline-flex">
                                            <span>Watch More</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="features">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src={require("../assets/img/how-it-works/redtie_dashboard.png")} className="img-fluid desk_show" alt="" />
                        </div>
                        <div className="col-lg-6 mt-lg-0 d-flex">
                            <div className="me-3 ms-3 align-self-center gy-4 how-it-works clr_black">
                                <h3 className="clr_black mob_txt_cntr pb-3">Dashboard</h3>
                                <p className="clr_black mob_txt_cntr">Using Redtie you can have multiple workspaces under a single account. The dashboard for every workspace helps you get a bird's eye view with the ability to drill down to details.</p>
                                <div className="row mt-4 mb-4">
                                    <div className="col-sm-12">
                                        <img src={require("../assets/img/how-it-works/redtie_dashboard.png")} className="img-fluid mob_show" alt="" />
                                        <div data-aos="fade-up" data-aos-delay={600}>
                                            <div className="text-center text-lg-start mt-3">
                                                <a href="https://app.redtie.co/" target="_blank" className="btn-get-started-section d-inline-flex">
                                                    <span>Read more</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="features bg_purble">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-6 mt-lg-0 d-flex">
                            <div className="me-3 ms-3 align-self-center gy-4 how-it-works clr_white">
                                <h3 className="mob_txt_cntr pb-3">Easy to use messaging</h3>
                                <p className="mob_txt_cntr">Create and send text messages with attachments - Images, Videos, Documents - just like an email. <br />
                                    Text Messages have an amazing 95% opening rate. Why waste time with emails that get ignored?</p>
                                <div className="row mt-4 mb-4">
                                    <div className="col-sm-12" data-aos="fade-up" data-aos-delay={700}>
                                        <img src={require("../assets/img/how-it-works/redtie-message-board.png")} className="img-fluid mob_show" alt="" />
                                        <div data-aos="fade-up" data-aos-delay={600}>
                                            <div className="text-center text-lg-start mt-3">
                                                <a href="https://app.redtie.co/" target="_blank"  className="btn-get-started d-inline-flex">
                                                    <span>Try Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={require("../assets/img/how-it-works/redtie-message-board.png")} className="img-fluid desk_show" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="features">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src={require("../assets/img/how-it-works/redtie_automation-workflows.jpg")} className="img-fluid desk_show" alt="" />
                        </div>
                        <div className="col-lg-6 mt-lg-0 d-flex">
                            <div className="me-3 ms-3 align-self-center gy-4 how-it-works clr_black">
                                <h3 className="mob_txt_cntr pb-3">Automation with Workflows</h3>
                                <p className="clr_black mob_txt_cntr">Let your communication standards stay ahead with Redtie's modern AI driven messaging features.</p>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>Improved efficiency</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>Increased productivity</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>Increased accuracy and reduction in deviations</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center mb-4">
                                    <i className="bi bi-check" />
                                    <h3>Increased visibility into work in almost real-time</h3>
                                </div>
                                <div className="row mt-4 mb-4">
                                    <div className="col-sm-12" data-aos="fade-up" data-aos-delay={700}>
                                        <img src={require("../assets/img/how-it-works/redtie_automation-workflows.jpg")} className="img-fluid mob_show" alt="" />
                                        <div data-aos="fade-up" data-aos-delay={600}>
                                            <div className="text-center text-lg-start mt-3">
                                                <a href="https://redtie.medium.com/"  target="_blank" className="btn-get-started-section d-inline-flex">
                                                    <span>Read more</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="features bg_purble">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-6 mt-lg-0 d-flex">
                            <div className="me-3 ms-3 align-self-center gy-4 how-it-works clr_white">
                                <h3 className="mob_txt_cntr pb-3">Introducing NFTs </h3>
                                <p className="mob_txt_cntr">
                                NFT, short for Nun-Fungible Token, is a digital record that cannot be destroyed because they are written at a unique location at their blockchain. Blockchain, a technology that has been evolving fast since the release of Bitcoin in 2008. Using Redtie, you can get a token issuing smartcontract attached to one or more of your workspaces, mint NFTs and simply attach them to your messages.</p>
                                <div className="row mt-4 mb-4">
                                    <div className="col-sm-12" data-aos="fade-up" data-aos-delay={700}>
                                        <img src={require("../assets/img/how-it-works/redtie_nft_1-01.png")} className="img-fluid mob_show" alt="" />
                                        <div data-aos="fade-up" data-aos-delay={600}>
                                            <div className="text-center text-lg-start mt-3">
                                                <a href="https://app.redtie.co/" target="_blank" className="btn-get-started d-inline-flex">
                                                    <span>Know more</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={require("../assets/img/how-it-works/redtie_nft_1-01.png")} className="img-fluid desk_show" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="features">
                <div className="container aos-init aos-animate" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src={require("../assets/img/how-it-works/redtie-text messaging Statistics.png")} className="img-fluid desk_show" alt="" />
                        </div>
                        <div className="col-lg-6 mt-lg-0 d-flex">
                            <div className="me-2 ms-2 align-self-center gy-4 how-it-works clr_black">
                                <h3 className="mob_txt_cntr pb-3">Mind-Blowing text messaging Statistics</h3>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>3.8 billion is the number of people that own smartphones in 2021.</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>75% of clients want to receive offers via SMS.</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>The CTR rate for offer messages is higher by 9.18% compared to any other digital channel.</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>SMS has a 98% open rate compared to email marketing.</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>60% of customers read texts within 1-5 minutes after receiving them.</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center mb-4">
                                    <i className="bi bi-check" />
                                    <h3>As of 2021, 67 million Americans redeem coupons via mobile phones.</h3>
                                </div>
                                <div className="row mt-4 mb-4">
                                    <div className="col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay={700}>
                                        <img src={require("../assets/img/how-it-works/redtie-text messaging Statistics.png")} className="img-fluid mob_show" alt="" />
                                        <div data-aos="fade-up" data-aos-delay={600} className="aos-init aos-animate">
                                            <div className="text-center text-lg-start mt-3">
                                                <a href="https://app.redtie.co/" target="_blank" className="btn-get-started-section d-inline-flex">
                                                    <span>Sign Up</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}

export default HowItWorks