import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Faq = () => {
  return (
    <>
      <Header />
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <header className="section-header mt-5 pt-5">
            <h2>F.A.Q</h2>
            <p>Frequently Asked Questions</p>
          </header>
          <div className="row">
            <div className="col-lg-12">
              {/* F.A.Q List 1*/}
              <div
                className="accordion accordion-flush justify-content-center"
                id="faqlist1"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      GENERAL
                    </button>
                  </h2>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist1"
                  >
                    <div className="accordion-body">
                      <p>
                        <strong>1. What is Redtie?</strong>
                      </p>
                      <p>
                        Redtie is an advanced feature rich text message solution
                        today. It is a SaaS platform that lets you send
                        attachments over a text message.
                      </p>
                      <p>
                        <strong>2. What can i do with Redtie?</strong>
                      </p>
                      <p>
                        You can share documents, photos, audio and videos over a
                        text message.
                      </p>
                      <p>
                        <strong>3. Who uses Redtie?</strong>
                      </p>
                      <p>
                        Small and medium scale businesses use Redtie text
                        messaging solutions to reach out and engage with their
                        customers.
                      </p>
                      <p>
                        <strong>4. What sets us apart?</strong>
                      </p>
                      <p>
                        Send multiple attachments at a time, Two-way messaging,
                        Auto Responders, Message Archiving, API for developers.
                      </p>
                      <p>
                        <strong>5. Which countries does Redtie support?</strong>
                      </p>
                      <p>Redtie is supported only in the United States</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq2-content-1"
                    >
                      BILLING AND PRICING
                    </button>
                  </h2>
                  <div
                    id="faq2-content-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist2"
                  >
                    <div className="accordion-body">
                      <p>
                        <strong>1. What are plans?</strong>
                      </p>
                      <p>
                        We offer 3 paid plans – Basic, Pro and Premium and a
                        Free trial for 24 hours
                      </p>
                      <p>
                        <strong>2. What is the pricing structure?</strong>
                      </p>
                      <p>
                        For more pricing info, check:
                        https://getredtie.com/pricing/
                      </p>
                      <p>
                        <strong>3. How to upgrade your plan?</strong>
                      </p>
                      <p>
                        Sign in to your profile -&gt; Go to Manage account -&gt;
                        Choose Billing -&gt; Upgrade plan
                      </p>
                      <p>
                        <strong>
                          4. How to cancel from a plan and choose a new plan?
                        </strong>
                      </p>
                      <p>
                        Sign in to your profile -&gt;Go to Manage account -&gt;
                        Choose Billing -&gt; Cancel.
                      </p>
                      <p>
                        <strong>5. Does Redtie charge Sales Tax?</strong>
                      </p>
                      <p>No</p>
                      <p>
                        <strong>6. Is there a refund?</strong>
                      </p>
                      <p>No</p>
                      <p>
                        <strong>7. Is there a setup fee?</strong>
                      </p>
                      <p>No, there is no setup fee.</p>
                      <p>
                        <strong>8. Is there any monthly charge?</strong>
                      </p>
                      <p>
                        Yes, you need to pay monthly for using the plan or you
                        can go for an annual billing.
                      </p>
                      <p>
                        <strong>9. How can I pay for my subscription?</strong>
                      </p>
                      <p>
                        You can pay using credit/debit card Sign in to your
                        profile -&gt; Go to Manage account -&gt; Choose Billing
                        and Pay.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      BUYING PHONE NUMBERS
                    </button>
                  </h2>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist1"
                  >
                    <div className="accordion-body">
                      <p>
                        <strong>
                          1. How to buy a dedicated number with Redtie?
                        </strong>
                      </p>
                      <p>
                        Create profile and choose the number as you wish by
                        specifying the area code or location.
                      </p>
                      <p>
                        <strong>2. How to search for Phone Numbers?</strong>
                      </p>
                      <p>
                        Search for phone numbers by specifying the area code or
                        location.
                      </p>
                      <p>
                        <strong>3. How to cancel a phone number?</strong>
                      </p>
                      <p>You cannot cancel a phone number once you buy it.</p>
                      <p>
                        <strong>
                          4. Can I change and opt for a new phone number?
                        </strong>
                      </p>
                      <p>
                        You cannot change the number for the same business, if
                        you opt for a new number, create a new profile and
                        purchase and new number.
                      </p>
                      <p>
                        <strong>
                          5. Can I have multiple phone numbers for my profiles?
                        </strong>
                      </p>
                      <p>You can have only 1 phone number/profile.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq2-content-2"
                    >
                      MESSAGE
                    </button>
                  </h2>
                  <div
                    id="faq2-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist2"
                  >
                    <div className="accordion-body">
                      <p>
                        <strong>
                          1. How to buy a dedicated number with Redtie?
                        </strong>
                      </p>
                      <p>
                        Create profile and choose the number as you wish by
                        specifying the area code or location.
                      </p>
                      <p>
                        <strong>2. How to search for Phone Numbers?</strong>
                      </p>
                      <p>
                        Search for phone numbers by specifying the area code or
                        location.
                      </p>
                      <p>
                        <strong>3. How to cancel a phone number?</strong>
                      </p>
                      <p>You cannot cancel a phone number once you buy it.</p>
                      <p>
                        <strong>
                          4. Can I change and opt for a new phone number?
                        </strong>
                      </p>
                      <p>
                        You cannot change the number for the same business, if
                        you opt for a new number, create a new profile and
                        purchase and new number.
                      </p>
                      <p>
                        <strong>
                          5. Can I have multiple phone numbers for my profiles?
                        </strong>
                      </p>
                      <p>You can have only 1 phone number/profile.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      ATTACHMENTS
                    </button>
                  </h2>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist1"
                  >
                    <div className="accordion-body">
                      <p>
                        <strong>
                          1. What type of attachments are allowed to send with a
                          message?
                        </strong>
                      </p>
                      <p>Documents:</p>
                      <p>
                        .DOC and DOCX, .HTML and .HTM, .ODT, .PDF, .XLS and
                        XLSX, .ODS, .PPT and .PPTX, .TXT
                      </p>
                      <p>Images:</p>
                      <p>JPEG, TIFF, PDF, BMP</p>
                      <p>Audio:</p>
                      <p>MP4, MP3, WAV, M4A</p>
                      <p>Videos:</p>
                      <p>MP4, MOV, WMV, WEBM, AVI</p>
                      <p>
                        <strong>
                          2. Is there a size limit for Attachments per message.
                        </strong>
                      </p>
                      <p>Yes, 100 mb</p>
                      <p>
                        <strong>
                          3. Can I receive attachments in my inbound message?
                        </strong>
                      </p>
                      <p>Yes</p>
                      <p>
                        <strong>
                          4. Why did my Attachments fail to get delivered?
                        </strong>
                      </p>
                      <p>
                        Invalid number, unsupported country, spam or virtual
                        number could be the reasons.
                      </p>
                      <p>
                        <strong>5. Can I send secure documents?</strong>
                      </p>
                      <p>Yes</p>
                      <p>
                        <strong>
                          6. Are my attachments safe and secure with Redtie?
                        </strong>
                      </p>
                      <p>Yes</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq2-content-3"
                    >
                      ACCOUNTS AND PROFILE
                    </button>
                  </h2>
                  <div
                    id="faq2-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist2"
                  >
                    <div className="accordion-body">
                      <p>
                        <strong>1. Can i change my account information?</strong>
                      </p>
                      <p>
                        Yes, you can reach us at – our support email –
                        getredtie@redtiemail.com or call this support number +1
                        484-874-5535.
                      </p>
                      <p>
                        <strong>2. How to reset my password?</strong>
                      </p>
                      <p>
                        By clicking the forgot password link on sign-in page.
                      </p>
                      <p>
                        <strong>3. How to add team members?</strong>
                      </p>
                      <p>
                        Go to Manage Account and Choose Team on the left pane.
                      </p>
                      <p>
                        <strong>4. Can I change the profile names?</strong>
                      </p>
                      <p>No</p>
                      <p>
                        <strong>5. How to cancel my account?</strong>
                      </p>
                      <p>
                        Yes, you can reach us at – our support email –
                        getredtie@redtiemail.com or call this support number +1
                        484-874-5535.
                      </p>
                      <p>
                        <strong>6. How to cancel a profile?</strong>
                      </p>
                      <p>
                        Go to Manage Account , Billing, Choose Profile and
                        Cancel.
                      </p>
                      <p>
                        <strong>
                          7. Can I claim my account back after cancelling?
                        </strong>
                      </p>
                      <p>
                        Yes, you can reach us at – our support email –
                        getredtie@redtiemail.com or call this support number +1
                        484-874-5535.
                      </p>
                      <p>
                        <strong>
                          8. Will I lose all my account info and data after
                          cancelling?
                        </strong>
                      </p>
                      <p>No</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Faq;
