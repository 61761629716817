import React from 'react'
import Header from "./Header"
import Banner from "./Banner"
import Features from "./Features"
import HowItWorks from "./HowItWorks";
import TwoWayMessage from "./TwoWayMessage";
import Footer from "./Footer";
import Integrations from "./Integrations";

function Home() {
  return (
    <>
      <Header />
      <Banner />
      <Features />
      <HowItWorks />
      <TwoWayMessage />
      <Integrations />
      <Footer />
    </>
  )
}

export default Home