import React from 'react'

const Features = () => {
    return (
        <section id="services" className="services">
            <div className="container" data-aos="fade-up">
                <header className="section-header">
                    <h2>Features</h2>
                    <p>Use Redtie to connect and share attachments with your stakeholders. Use workflow for code-free automation and the API to BUIDL.</p>
                </header>
                <div className="row gy-4">
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box purple_clr"> 
                            <img src={ require("../assets/img/features/redtie-feature-two-way-messaging.png")} className="img-fluid mb-3" alt="" />                           
                            <h3>SMS</h3>
                            <p>Redtie's underlying protocol is SMS. You can simply send/receive text messages with or without attachments. You can also save and schedule the messages to be sent in the future.</p>
                            <a href="https://redtie.medium.com/" target="_blank" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box orange">
                            <img src={ require("../assets/img/features/redtie-feature-auto-responder.png")} className="img-fluid mb-3" alt="" />
                            <h3>Automation</h3>
                            <p>Make use of Redtie's code-free workflow to automate messaging workloads. If you are a bit more tech savvy, make use of the webhooks for deeper integrations.</p>
                            <a href="https://redtie.medium.com/" target="_blank" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box purple_clr">                            
                            <img src={ require("../assets/img/features/redtie-feature-message-archiving-1.png")} className="img-fluid mb-3" alt="" />
                            <h3>Compliant</h3>
                            <p>If your business requires messages to be archived, Redtie provides this feature out of the box. Just turn it on and stay compliant.</p>
                            <a href="https://redtie.medium.com/" target="_blank" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box orange">                            
                            <img src={ require("../assets/img/features/redtie-feature-attachment.png")} className="img-fluid mb-3" alt="" />
                            <h3>Attachments</h3>
                            <p>Documents, Images, Audio, Video, Meeting or Signature requests and hot off the press, NFTs, can be attached to your messages.</p>
                            <a href="https://redtie.medium.com/" target="_blank" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box purple_clr">
                            <img src={ require("../assets/img/features/redtie-feature-integrations.png")} className="img-fluid mb-3" alt="" />
                            <h3>Integrations</h3>
                            <p>Redtie has integrations with Gmail, Slack as well as Zapier which allows you to integrate with hundreds of products and platforms.</p>
                            <a href="https://redtie.medium.com/" target="_blank" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-box orange">
                            <img src={ require("../assets/img/features/redtie-feature-automation-01.png")} className="img-fluid mb-3" alt="" />
                            <h3>BYON</h3>
                            <p>A workspace may be created with Redtie providing the number or you can bring your own provider credentials.</p>
                            <a href="https://redtie.medium.com/" target="_blank" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right" /></a>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mb-4">
                    <div className="col-sm-12 d-flex justify-content-center">
                        <div data-aos="fade-up">
                            <div className="text-center text-lg-start">
                                <a href="https://app.redtie.co/" target="_blank" className="btn-get-started-section d-inline-flex">
                                    <span>Try Us Free</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Features