import "./assets/css/style.css"
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Pricing from "./components/Pricing";
import ContactUs from "./components/ContactUs";
import Faq from "./components/Faq";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsandConditions from "./components/TermsandConditions";
import Home from "./components/Home";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsandConditions />} />
        </Routes>
      </BrowserRouter>
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" /></a>
    </>
  );
}


export default App;
