import React from 'react'

const Footer = (props) => {
    const d = new Date();
    let year = d.getFullYear();
    return (
        <footer id="footer" className="footer bg_purble">
            <div className="footer-newsletter bg_purble">
                <div className="container bg_purble">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <h4>App is available for free on Google Play &amp; App Store</h4>
                            <p className="mt-3">We’ll help you achieve your marketing &amp; business goals</p>
                        </div>
                        <div className="col-lg-12 bg_purble">
                        <div className="row gy-4 justify-content-center mt-5">
                            <div className="col-lg-12 col-md-12 col-sm-12 aos-init aos-animate d-flex justify-content-center" data-aos="fade-up" data-aos-delay={700}>
                                <div data-aos="fade-up" data-aos-delay={600} className="aos-init aos-animate ms-5">
                                <div className="text-center text-lg-start">
                                    <a href="https://apps.apple.com/us/app/redtie-biz/id1543530724" target="_blank" className="btn-get-started-section d-inline-flex mob_pad_btn">
                                    <span className="mob_f_sze_13 d-flex align-items-center">
                                        <i className="bi bi-apple me-2" />
                                        App Store
                                    </span>
                                    </a>
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 aos-init aos-animate d-flex justify-content-center" data-aos="fade-up" data-aos-delay={700}>
                                <div data-aos="fade-up" data-aos-delay={600} className="aos-init aos-animate ms-5">
                                <div className="text-center text-lg-start">
                                    <a href="https://play.google.com/store/apps/details?id=com.ephron.redtie.admin" target="_blank" className="btn-get-started-section d-inline-flex mob_pad_btn">
                                    <span className="mob_f_sze_13">
                                    <img src={require("../assets/img/feature_icon/google-play-store.png")} className="img-fluid play_store_img me-2" alt="" />
                                        Play Store
                                    </span>
                                    </a>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4 justify-content-center">
                        <div className="col-lg-12 col-12 footer-links">
                            <div className="social-links clr_white text-center d-flex flex-row flex-wrap justify-content-center bd-highlight mb-3 text-md-start">
                                <a href="https://twitter.com/getredtie" className="twitter" title="Twitter"><i className="bi bi-twitter clr_white" /></a>
                                <a href="https://www.facebook.com/GetRedtie2017/" className="facebook" title="Facebook"><i className="bi bi-facebook clr_white" /></a>
                                <a href="https://www.youtube.com/Redtie/" className="youtube" title="Youtube"><i className="bi bi-youtube clr_white" /></a>
                                <a href="https://redtie.medium.com/" className="medium" title="Medium"><i className="bi bi-medium clr_white" /></a>
                                <a href="https://in.pinterest.com/getredtie/" className="pinterest" title="Pinterest"><i className="bi bi-pinterest clr_white" /></a>
                                <a href="https://www.instagram.com/getredtie/" className="instagram" title="Instagram"><i className="bi bi-instagram clr_white" /></a>
                                <a href="https://www.linkedin.com/company/getredtie" className="linkedin" title="Linkedin"><i className="bi bi-linkedin clr_white" /></a>
                            </div>
                        </div>
                        <div className="col-lg-12 col-12 footer-links">
                            <ul className="clr_white text-center d-flex flex-row flex-wrap justify-content-center bd-highlight mb-3 text-md-start">
                                <li className="text-center"> <a href="https://docsv4-app.redtie.co/">API</a></li>
                                {/* <li className="text-center"> <a href="https://getredtie.com/blog/">Blog</a></li> */}
                                <li className="text-center"> <a href='/contactus'>Contact Us</a></li>
                                <li className="text-center"> <a href='/faq'>FAQ</a></li>
                                <li className="text-center"> <a href='/privacy-policy'>Privacy policy</a></li>
                                <li className="text-center"> <a href='/terms-conditions'>Terms &amp; Conditions</a></li>
                                {/* <li className="text-center"> <a href="https://getredtie.com/hipaa-compliance/">HIPAA Compliance</a></li> */}
                            </ul>
                        </div>
                        <div className="col-lg-12 col-12 col-sm-12 footer-contact ">
                            <p className="clr_white text-center clr_white text-center d-flex flex-row flex-wrap justify-content-center bd-highlight mb-3 text-md-start">
                                <strong className="me-3 ms-3">Phone:</strong> <a className="link_footer" href="tel:+484-874-5535" /> +1 484-874-5535<br />
                                <strong className="me-3 ms-3">Email:</strong> <a className="link_footer" href="mailto:getredtie@redtiemail.com">getredtie@redtiemail.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    © Copyright <strong><span>getredtie.com {year}</span></strong>
                </div>
            </div>
        </footer>

    )
}

export default Footer