import React from "react";
const Banner = () => {
    return (
        <section id="hero" className="hero d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column justify-content-center">
                        <h1 data-aos="fade-up">Messaging <br />gateway for <br />everything </h1>
                        <div data-aos="fade-up" data-aos-delay={600}>
                            <div className="text-center text-lg-start mt-5">
                                <a href="https://app.redtie.co/" target="_blank" className="btn-get-started-section d-inline-flex">
                                    <span>Get Started</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay={200}>
                        <img src={ require("../assets/img/redtie-banner-chat.png")} alt=""  className="img-fluid img_front" />
                    </div>
                </div>
            </div>
        </section>

    );
}
export default Banner;
