import React from 'react'

const TwoWayMessage = () => {
    return (
        <section id="features" className="features bg_purble">
            <div className="container aos-init aos-animate" data-aos="fade-up">
                <header className="section-header clr_white mob_btm_0">
                    <h2 className="clr_white">See how businesses can use Two-Way messaging</h2>
                    <p className="clr_white">Redtie is the smart way to connect with your customers. With the features below, you can have better engagement with them.</p>
                </header>
                <div className="row feature-icons aos-init aos-animate" data-aos="fade-up">
                    <div className="row">
                        <div className="col-xl-4 col-4 pe-5 d-flex content">
                            <div className="row align-self-center gy-4 mt-2">
                                <div className="col-md-12 icon-box aos-init aos-animate justify-content-end two-Way-icon" data-aos="fade-up">
                                    <div className="d-flex justify-content-center feature_icon">
                                        <h4 className="align-self-center">Marketing</h4>
                                        <img src={require("../assets/img/feature_icon/marketing-redtie.png")} className="img-fluid ms-3 img_icons" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-12 icon-box aos-init aos-animate justify-content-end two-Way-icon" data-aos="fade-up" data-aos-delay={200}>
                                    <div className="d-flex justify-content-center feature_icon">
                                        <h4 className="align-self-center">Sales</h4>
                                        <img src={require("../assets/img/feature_icon/sales-redtie.png")} className="img-fluid ms-3 img_icons" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-12 icon-box aos-init aos-animate justify-content-end two-Way-icon" data-aos="fade-up" data-aos-delay={400}>
                                    <div className="d-flex justify-content-center feature_icon">
                                        <h4 className="align-self-center">Customer&nbsp;&nbsp;Service</h4>
                                        <img src={require("../assets/img/feature_icon/customer-service-redtie.png")} className="img-fluid ms-3 img_icons" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-12 icon-box aos-init aos-animate justify-content-end two-Way-icon" data-aos="fade-up" data-aos-delay={400}>
                                    <div className="d-flex justify-content-center feature_icon">
                                        <h4 className="align-self-center">Announcements</h4>
                                        <img src={require("../assets/img/feature_icon/announcements-redtie.png")} className="img-fluid ms-3 img_icons" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-4 desk_show text-center hide_small aos-init aos-animate" data-aos="fade-right" data-aos-delay={100}>
                            <img src={require("../assets/img/redtie-mob-app-screen-3-1024x1024.png")} className="img-fluid p-4 two-Way-mob-img" alt="" />
                        </div>
                        <div className="col-xl-4 col-4 ps-5 d-flex content">
                            <div className="row align-self-center gy-4 mt-2">
                                <div className="col-md-12 icon-box aos-init aos-animate two-Way-icon" data-aos="fade-up" data-aos-delay={100}>
                                    <div className="d-flex justify-content-center feature_icon ps-3">
                                        <img src={require("../assets/img/feature_icon/customer-support-redtie.png")} className="img-fluid me-3 img_icons" alt="" />
                                        <h4 className="align-self-center">Customer&nbsp;&nbsp;Support</h4>
                                    </div>
                                </div>
                                <div className="col-md-12 icon-box aos-init aos-animate two-Way-icon" data-aos="fade-up" data-aos-delay={300}>
                                    <div className="d-flex justify-content-center feature_icon ps-3">
                                        <img src={require("../assets/img/feature_icon/reminders-redtie.png")} className="img-fluid me-3 img_icons" alt="" />
                                        <h4 className="align-self-center">Reminders</h4>
                                    </div>
                                </div>
                                <div className="col-md-12 icon-box aos-init aos-animate two-Way-icon" data-aos="fade-up" data-aos-delay={500}>
                                    <div className="d-flex justify-content-center feature_icon ps-3">
                                        <img src={require("../assets/img/feature_icon/customer-engagement-redtie.png")} className="img-fluid me-3 img_icons" alt="" />
                                        <h4 className="align-self-center">Customer&nbsp;&nbsp;Engagement</h4>
                                    </div>
                                </div>
                                <div className="col-md-12 icon-box aos-init aos-animate two-Way-icon" data-aos="fade-up" data-aos-delay={500}>
                                    <div className="d-flex justify-content-center feature_icon ps-3">
                                        <img src={require("../assets/img/feature_icon/product-launch-redtie.png")} className="img-fluid me-3 img_icons" alt="" />
                                        <h4 className="align-self-center">Product&nbsp;&nbsp;Launches</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 mb-4">
                        <div className="col-sm-12 d-flex justify-content-center">
                            <div data-aos="fade-up">
                                <div className="text-center text-lg-start">
                                    <a href="https://app.redtie.co/" target="_blank" className="btn-get-started d-inline-flex">
                                        <span>Try Now</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default TwoWayMessage